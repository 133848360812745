import React, { useState } from 'react';
import { AlertCircle, CheckCircle2, Star } from 'lucide-react';
import VALID_USERS from './users';
// Update environment variable name to include REACT_APP_ prefix
const EVAL_API = process.env.REACT_APP_EVAL_API || 'http://localhost:8000';

function App() {
  const [studentId, setStudentId] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [topic, setTopic] = useState('');
  const [text, setText] = useState('');
  const [feedback, setFeedback] = useState([]);
  const [error, setError] = useState('');
  const [sentenceAnalysis, setSentenceAnalysis] = useState([]);
  const [coverage, setCoverage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [loginForm, setLoginForm] = useState({
    studentId: '',
    password: ''
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Add login handler
  const handleLogin = (e) => {
    e.preventDefault();
    const user = VALID_USERS.find(u => 
      u.studentId === loginForm.studentId && 
      u.password === loginForm.password
    );

    if (user) {
      setIsAuthenticated(true);
      setStudentId(loginForm.studentId);
      setLoginError('');
    } else {
      setLoginError('Invalid student ID or password!');
    }
  };

  // Add login page JSX
  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-lg shadow">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              📝 PEEL Writing Guide
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Please sign in to continue
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleLogin}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Student ID"
                  value={loginForm.studentId}
                  onChange={(e) => setLoginForm({...loginForm, studentId: e.target.value})}
                />
              </div>
              <div>
                <input
                  type="password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={loginForm.password}
                  onChange={(e) => setLoginForm({...loginForm, password: e.target.value})}
                />
              </div>
            </div>

            {loginError && (
              <div className="text-red-500 text-sm text-center">
                {loginError}
              </div>
            )}

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  const analyzeParagraph = async () => {
    if (!studentId.trim()) {
      setError('Student ID is required!');
      return;
    }
    
    setError('');
    setIsLoading(true);
    try {
      const response = await fetch(EVAL_API + '/api/analyze', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          text, 
          topic,
          studentId,
          date 
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'An error occurred while analyzing the paragraph, please try again.');
      }

      const data = await response.json();
      setFeedback(data.feedback);
      setSentenceAnalysis(data.sentence_analysis);
      setCoverage(data.coverage);
      
      if (Math.max(1 + data.coverage, 0) >= 1) {
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), data.coverage >= 4 ? 10000 : 3000);
      }
    } catch (error) {
      console.error('Error:', error);
      setFeedback([{
        type: 'error',
        message: error.message || 'An error occurred while analyzing the paragraph, please try again.',
        level: 0
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateRating = () => {
    if (sentenceAnalysis.length === 0) return 0;
    // const warningCount = feedback.filter(item => item.type === 'warning').length;
    return Math.max(1 + coverage, 0);
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6">
      {/* Add loading overlay */}
      {isLoading && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center gap-4">
            <svg className="animate-spin h-16 w-16 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <p className="text-lg font-semibold text-gray-700">Analyzing your paragraph...</p>
            <p className="text-sm font-semibold text-gray-700">(This may take a few seconds)</p>
          </div>
        </div>
      )}
      
      {/* Add success message overlay */}
      {showSuccessMessage && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center gap-4">
            <img 
              src={coverage >= 4 ? "/great.png" : "/keep_trying.png"} 
              alt={coverage >= 4 ? "Great!" : "Keep Trying!"} 
              className="w-64 h-64" 
            />
            <p className="text-2xl font-semibold text-gray-700">
              {coverage >= 4 ? "You did great!" : "Improve it following the feedback!"}
            </p>
          </div>
        </div>
      )}
      
      <div className="w-[80%] mx-auto p-6">
        <div className="bg-white shadow rounded-lg">
          <div className="p-6">
            <div className="grid grid-cols-3 gap-4 mb-4">
              <div className="col-span-1">
                <label className="block text-gray-700 text-2xl font-bold mb-2" htmlFor="peel-writing-guide">
                  📝 PEEL Writing Guide
                </label>
              </div>
              <div className="col-span-2">
                <div className="flex items-center gap-4 justify-end">
                  {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
                  <div className="flex items-center gap-2">
                    <label className="block text-gray-700 text-sm font-bold whitespace-nowrap" htmlFor="student-id">
                      Student ID:
                    </label>
                    <input
                      type="text"
                      className={`p-2 border rounded-lg w-48 ${error ? 'border-red-500' : ''}`}
                      placeholder="Student ID... *"
                      value={studentId}
                      onChange={(e) => {
                        setStudentId(e.target.value);
                        if (error) setError('');
                      }}
                      readOnly
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <label className="block text-gray-700 text-sm font-bold whitespace-nowrap" htmlFor="date">
                      Date:
                    </label>
                    <input
                      type="date"
                      className="p-2 border rounded-lg w-48 bg-gray-100"
                      value={date}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="topic">
                Topic
              </label>
              <select
                id="topic"
                className="w-full p-2 border rounded-lg bg-white"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              >
                <option value="">Select a topic...</option>
                <option>Homework should be banned in primary school</option>
                <option>School uniforms should not be worn in school</option>
                <option>School should start later</option>
                <option>Students should choose what subjects to study</option>
                <option>Children should have personal electronic devices</option>
                <option>Cats are better than dogs</option>
                <option>Flying is better than being invisible</option>
                <option>Why is it important to show kindness to others?</option>
              </select>
            </div>
            
            <div className="grid grid-cols-2 gap-4 mb-2">
              <div className="mb-2">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="paragraph">
                  Paragraph
                </label>
                <textarea
                  id="paragraph"
                  className="w-full min-h-[16rem] p-4 border rounded-lg"
                  placeholder="Enter your paragraph here..."
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              </div>

              <div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sentence-analysis">
                      PEEL Analysis
                    </label>
                    <div className="h-64 p-4 bg-gray-50 rounded-lg overflow-auto select-none">
                      {sentenceAnalysis.map((sentence, index) => (
                        <span
                          key={index}
                          className={`
                            whitespace-pre-wrap
                            ${sentence.category === 'point' ? 'text-[#A65D57]' : ''}
                            ${sentence.category === 'evidence' ? 'text-[#4A6FA5]' : ''}
                            ${sentence.category === 'explanation' ? 'text-[#5B8A72]' : ''}
                            ${sentence.category === 'link' ? 'text-[#C2956A]' : ''}
                            ${sentence.category === 'irrelevant' ? 'text-[#A9A9A9] line-through' : ''}
                            ${sentence.category === 'plain_feedback' ? 'text-[#767676]' : ''}
                            ${sentence.level < 3 ? 'font-bold' : ''}
                          `}
                        >
                          {sentence.sentence}{' '}
                        </span>
                      ))}
                    </div>
                  </div>
              </div>
            </div>
            
            <div className="flex items-center justify-between gap-4 mb-4">
              <button 
                className="w-[50%] px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center justify-center gap-2"
                onClick={analyzeParagraph}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Analyzing...
                  </>
                ) : (
                  'Analyze Paragraph'
                )}
              </button>
              <div className="flex items-center gap-1">
                {[1, 2, 3, 4, 5].map((star) => (
                  <Star
                    key={star}
                    className={`w-10 h-10 ${
                      star <= calculateRating() ? 'fill-yellow-400 text-yellow-400' : 'text-gray-300'
                    }`}
                  />
                ))}
              </div>
            </div>
            
            <div className="space-y-3">
              {feedback
                .filter(item => item.level < 3)
                .map((item, index) => (
                  <div 
                    key={index} 
                    className={`flex items-start gap-2 p-3 rounded-lg ${
                    item.type === 'Point' ? 'bg-[#A65D57]/10 text-[#A65D57]' :
                    item.type === 'Evidence' ? 'bg-[#4A6FA5]/10 text-[#4A6FA5]' :
                    item.type === 'Explanation' ? 'bg-[#5B8A72]/10 text-[#5B8A72]' :
                    item.type === 'Link' ? 'bg-[#C2956A]/10 text-[#C2956A]' :
                    item.type === 'error' ? 'bg-red-100 text-red-600' :
                    'bg-gray-50 text-gray-800'
                  }`}
                >
                  <CheckCircle2 className="w-5 h-5 mt-0.5" />
                  <p>{item.message}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Add footer */}
        <footer className="mt-8 text-center text-gray-600 text-sm">
          ©2025, Institute for Infocomm Research, A*STAR.
        </footer>
      </div>
    </div>
  );
}

export default App;